<template>
    <TransitionFade>
        <div v-if="isOpen"
             class="fixed z-[51] h-screen w-screen overflow-y-hidden backdrop-blur-[1px]"
             style="background-color: rgba(0,0,0, 0.1)"
             @click="close" />
    </TransitionFade>
    <div class="editor fixed right-0 top-0 z-[51] h-full overflow-y-auto bg-base-100 px-16 py-8 shadow"
         :class="{ isOpen }">
        <div class="text-color-muted mb-12 flex items-center justify-between">
            <h1>Ingredient Editor</h1>
            <Icon name="close"
                  tag="button"
                  title="Close editor"
                  class="bgn-ghost btn btn-square btn-sm"
                  @click="isOpen = false" />
        </div>
        <div ref="container" class="relative space-y-6">
            <div ref="nameContainer" class="form-control h-full items-start">
                <label for="name" class="label inline-flex">
                    Name
                </label>
                <input id="name"
                       v-model="ingredient.name"
                       type="text"
                       required
                       class="input input-bordered w-full bg-base-200/30"
                       @click="dropdownOpen = true">
                <details class="dropdown z-10 w-full"
                         :open="!!potentialMatches.length && dropdownOpen">
                    <summary class="hidden" />
                    <ul class="menu dropdown-content mt-2 max-h-48 w-full flex-nowrap overflow-y-auto
                               rounded-box bg-base-100 p-2 shadow">
                        <li v-for="(ingr, index) in potentialMatches"
                            :key="index"
                            tabindex="0"
                            class="cursor-pointer rounded px-4 py-3 transition-colors hover:bg-base-200"
                            @keyup.enter="updateIngr(ingr)"
                            @click="updateIngr(ingr)">
                            {{ ingr.name }}
                        </li>
                    </ul>
                </details>
            </div>
            <div class="form-control">
                <label class="label cursor-pointer justify-start gap-2">
                    <input v-model="ingredient.isAllergen"
                           type="checkbox"
                           class="checkbox">
                    Is An Allergen
                </label>
            </div>
            <div class="form-control items-start">
                <label for="cost" class="label inline-flex">
                    Cost
                </label>
                <div class="join w-full">
                    <label for="cost" class="join-item select-none border border-base-300 bg-base-300 px-4 py-2.5">
                        £
                    </label>
                    <input id="cost"
                           v-model="ingredient.cost"
                           type="number"
                           min="0.00"
                           required
                           step="0.01"
                           pattern="^[0-9]+(\.[0-9]{1,2})?$"
                           class="input join-item input-bordered w-full bg-base-200/30">
                </div>
            </div>
            <div class="form-control items-start">
                <label for="name" class="label inline-flex">
                    Unit
                    <span class="tooltip tooltip-top" data-tip="The unit the ingredient is measured in.">
                        <Icon name="help" class="ml-1" size="20" />
                    </span>
                </label>
                <select v-model="ingredient.unitId"
                        class="select select-bordered w-full bg-base-200/30"
                        required
                        placeholder="Please select a unit.">
                    <optgroup label="Absolute units">
                        <option v-for="unit in unitStore.absoluteUnits"
                                :key="unit.id"
                                :selected="unit.id === unit.id"
                                :value="unit.id"
                                v-text="unit.name ?? unit.abbreviation" />
                    </optgroup>
                    <optgroup label="Relative units">
                        <option v-for="unit in unitStore.relativeUnits"
                                :key="unit.id"
                                :selected="unit.id === unit.id"
                                :value="unit.id"
                                v-text="unit.name ?? unit.abbreviation" />
                    </optgroup>
                </select>
            </div>
            <div class="form-control items-start">
                <label for="amount" class="label inline-flex">
                    Unit Amount
                </label>
                <div class="join w-full">
                    <input id="amount"
                           v-model="ingredient.unitAmount"
                           type="number"
                           min="0.01"
                           required
                           step="1"
                           placeholder="Quantity of the unit type."
                           :class="{ 'join-item': !!suffix }"
                           class="input input-bordered w-full bg-base-200/30">
                    <TransitionExpand axis="x">
                        <label v-if="suffix" for="amount" class="join-item select-none border bg-base-300 px-4 py-2.5">
                            {{ suffix }}
                        </label>
                    </TransitionExpand>
                </div>
            </div>
        </div>
        <div class="mt-6 flex flex-row justify-end">
            <button :disabled="loading"
                    :class="[isCreatingIngredient ? 'btn-success' : 'btn-info']"
                    class="btn mt-8 !px-8"
                    @click="save">
                {{ isCreatingIngredient ? 'Save' : 'Update' }}
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import type { IngredientAttributes, UnitAttributes } from '~/types';
import { plural } from '@upfrontjs/framework';
import Icon from '~/components/Icon.vue';
import useIngredientStore from '~/stores/ingredient';
import parseError from '~/utils/parseError';
import apiFetch from '~/utils/apiFetch';
import { TransitionFade, TransitionExpand } from '@morev/vue-transitions';
import { onClickOutside } from '@vueuse/core';
import useUnitStore from '~/stores/unit';
import { useToast } from 'vue-toastify';

// todo add precognition requests here
export default defineComponent({
    name: 'IngredientEditor',

    components: {
        Icon,
        TransitionFade,
        TransitionExpand
    },

    setup: () => {
        const loading = ref(false);
        const ingredientStore = useIngredientStore();
        const unitStore = useUnitStore();
        const isOpen = ref(false);
        const toast = useToast();
        const ingredient = ref<IngredientAttributes>({
            id: NaN,
            isAllergen: false,
            name: '',
            unitAmount: NaN,
            cost: NaN,
            unitId: NaN
        });
        const unit = computed<UnitAttributes | undefined>(() => {
            return unitStore.units.find(u => u.id === ingredient.value.unitId);
        });
        const isCreatingIngredient = computed(() => isNaN(ingredient.value.id));
        const suffix = computed(() => {
            if (!ingredient.value.unitAmount || !unit.value) {
                return '';
            }

            if (ingredient.value.unitAmount <= 1) {
                return unit.value.name;
            }

            return unitStore.relativeUnits.map(u => u.abbreviation).includes(unit.value.abbreviation)
                ? unit.value.name ?? unit.value.abbreviation
                : unit.value.name ? plural(unit.value.name) : unit.value.abbreviation;
        });
        const potentialMatches = computed<IngredientAttributes[]>(() => {
            return ingredient.value.name
                ? ingredientStore.ingredients.filter(
                    i => i.name.toLowerCase().includes(ingredient.value.name.toLowerCase())
                )
                : [];
        });
        const dropdownOpen = ref(false);
        const nameContainer = ref<HTMLDivElement>();

        onClickOutside(nameContainer, () => dropdownOpen.value = false);
        const open = () => isOpen.value = true;
        const close = () => isOpen.value = false;
        const reset = () => {
            ingredient.value = {
                id: NaN,
                isAllergen: false,
                name: '',
                unitAmount: NaN,
                cost: NaN,
                unitId: NaN
            };
        };
        const openFresh = () => {
            reset();
            open();
        };
        const updateIngr = (ingr: IngredientAttributes) => {
            ingredient.value = ingr;
            dropdownOpen.value = false;
        };

        const save = async () => {
            loading.value = true;
            return apiFetch<IngredientAttributes>(
                isCreatingIngredient.value ? '/ingredients' : `/ingredients/${ingredient.value.id}`,
                {
                    method: isCreatingIngredient.value ? 'POST' : 'PATCH',
                    body: ingredient.value
                })
                .then(ingredient => {
                    if (isCreatingIngredient.value) {
                        ingredientStore.ingredients.push(ingredient);
                        return;
                    }

                    ingredientStore.ingredients = ingredientStore.ingredients.map(ingr => {
                        if (ingr.id === ingredient.id) {
                            // retain product count
                            ingr = { ...ingr, ...ingredient };
                        }

                        return ingr;
                    });
                    return;
                })
                .then(() => {
                    toast.success(`Ingredient ${isCreatingIngredient.value ? 'created' : 'updated'}.`);
                    close();
                    return;
                })
                .catch(reason => {
                    const handler = parseError(reason);

                    if (handler.isValidationError) {
                        toast.error(handler.errorMessage);
                        return;
                    }
                })
                .finally(() => loading.value = false);
        };

        const editIngredient = (attributes: IngredientAttributes) => {
            ingredient.value = attributes;

            open();
        };

        return {
            ingredient,
            unitStore,
            save,
            suffix,
            editIngredient,
            open,
            close,
            isOpen,
            openFresh,
            potentialMatches,
            nameContainer,
            dropdownOpen,
            loading,
            isCreatingIngredient,
            updateIngr
        };
    },

    expose: ['editIngredient', 'open', 'close', 'openFresh', 'isOpen']
});
</script>

<style lang="scss" scoped>
.editor {
    width: 100vw;
    opacity: 0;
    margin-right: -100vw;
    transition: margin-right 250ms ease-in, opacity 250ms ease;

    &.isOpen {
        opacity: 1;
        transition: margin-right 250ms ease-out, opacity 250ms ease;
        margin-right: 0;
    }

    @media (min-width: theme('screens.sm')) {
        width: 80vw;
        margin-right: -80vw;
    }

    @media (min-width: theme('screens.md')) {
        width: 60vw;
        margin-right: -60vw;
    }

    @media (min-width: theme('screens.lg')) {
        width: 50vw;
        margin-right: -50vw;
    }

    @media (min-width: theme('screens.xl')) {
        width: 45vw;
        margin-right: -45vw;
    }

    @media (min-width: theme('screens.2xl')) {
        width: 35vw;
        margin-right: -35vw;
    }
}
</style>
