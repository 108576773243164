<template>
    <div class="drawer">
        <NuxtRouteAnnouncer />
        <input id="drawer" type="checkbox" class="drawer-toggle">
        <div class="drawer-content flex flex-col">
            <!-- Navbar -->
            <div class="navbar w-full justify-between bg-base-300 shadow-base-200">
                <label for="drawer" class="btn btn-square btn-ghost">
                    <Icon name="menu" />
                </label>
                <ul class="flex items-center justify-between px-2">
                    <li>
                        <button title="Add ingredient" class="btn btn-ghost rounded" @click="() =>addIngredient()">
                            <span class="inline-flex p-0">
                                <Icon name="plus" />
                                <Icon name="ingredients" />
                            </span>
                        </button>
                    </li>
                    <li>
                        <div class="dropdown dropdown-end dropdown-bottom rounded">
                            <label tabindex="0">
                                <button class="btn btn-ghost">
                                    <div class="avatar placeholder">
                                        <div class="w-8 rounded-full bg-neutral text-neutral-content">
                                            <span class="text-xs">{{ userName.at(0) }}</span>
                                        </div>
                                    </div>
                                </button>
                            </label>
                            <ul tabindex="0" class="menu dropdown-content z-10 rounded-box bg-base-100 shadow">
                                <li role="menuitem">
                                    <NuxtLink :to="{ name: 'admin-settings' }">
                                        Settings
                                    </NuxtLink>
                                </li>
                                <li role="menuitem">
                                    <button @click="logout">
                                        Log out
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- Content -->
            <div class="container py-4 md:px-12">
                <slot />
            </div>
        </div>
        <!-- Sidebar -->
        <div class="drawer-side">
            <label for="drawer" class="drawer-overlay" />
            <ul class="menu h-screen w-80 space-y-2 overflow-y-auto bg-base-100 p-4">
                <li class="border-b border-primary/60 pb-2">
                    <NuxtLink to="/admin" class="link mt-12" @click="closeMenu">
                        <Icon name="home" class="mr-3" />
                        Home
                    </NuxtLink>
                </li>

                <li class="border-b border-primary/60 pb-2">
                    <NuxtLink :to=" { name: 'admin-products' }" class="link py-3" @click="closeMenu">
                        <Icon name="product" class="mr-3" />
                        Products
                    </NuxtLink>
                </li>
                <li>
                    <NuxtLink :to=" { name: 'admin-ingredients' }" class="link" @click="closeMenu">
                        <Icon name="ingredients" class="mr-3" />
                        Ingredients
                    </NuxtLink>
                </li>
                <li>
                    <NuxtLink :to=" { name: 'admin-units' }" class="link" @click="closeMenu">
                        <Icon name="unit" class="mr-3" />
                        Units
                    </NuxtLink>
                </li>
                <li>
                    <NuxtLink :to=" { name: 'admin-packagings' }" class="link" @click="closeMenu">
                        <Icon name="box" class="mr-3" />
                        Packagings
                    </NuxtLink>
                </li>
                <li>
                    <NuxtLink :to=" { name: 'admin-galleries' }" class="link" @click="closeMenu">
                        <Icon name="gallery" class="mr-3" />
                        Galleries
                    </NuxtLink>
                </li>
            </ul>
        </div>
        <IngredientEditor ref="ingredientEditor" />
    </div>
</template>

<script lang="ts">
import { defineComponent, provide, ref, watch } from 'vue';
import IngredientEditor from '~/components/admin/IngredientEditor.vue';
import { useLoader } from '~/composables';
import useIngredientStore from '~/stores/ingredient';
import type { IngredientAttributes, IngredientEditorMethods } from '~/types';
import useAppStore from '~/stores/app';
import { useNuxtApp } from '#imports';
import { ingredientEditorKey } from '~/fixtures';
import useAuthStore from '~/stores/auth';
import apiFetch from '~/utils/apiFetch';
import type { ProductType } from '~/composables/useProductTypes';
import useProductTypes from '~/composables/useProductTypes';
import type { PackagingAttributes, UnitAttributes } from '~/types';
import useUnitStore from '~/stores/unit';
import usePackagingStore from '~/stores/packaging';
import useProductStore from '~/stores/products';
import Toast from 'vue-toastify';
import type { Settings } from 'vue-toastify';
import 'vue-toastify/index.css';

export default defineComponent({
    name: 'Admin',

    components: {
        IngredientEditor
    },

    setup: async () => {
        const nuxt = useNuxtApp();
        const ingredientEditor = ref<IngredientEditorMethods>();
        const ingredientStore = useIngredientStore();
        const unitStore = useUnitStore();
        const packagingStore = usePackagingStore();
        const productStore = useProductStore();
        const productTypes = useProductTypes();
        const appStore = useAppStore();
        const authStore = useAuthStore();
        const loader = useLoader();
        const userName = ref(authStore.user?.name ?? '');

        provide(ingredientEditorKey, ingredientEditor);

        // todo - find a better way for checking if this was installed
        onMounted(() => {
            if (!document.getElementById(Symbol('vue-toastify').toString())) {
                nuxt.vueApp.use<Settings>(Toast, {
                    errorDuration: 3000,
                    successDuration: 1500,
                    pauseOnHover: true
                });
            }
        });

        const logout = async () => {
            loader?.on();

            return authStore.logout()
                .then(async () => navigateTo({ path: '/' }))
                .catch(async () => navigateTo({ path: '/login' }))
                .finally(loader?.off);
        };

        const addIngredient = () => {
            ingredientEditor.value!.openFresh();
            (document.activeElement as HTMLButtonElement).blur();
        };
        const closeMenu = () => {
            document.getElementById('drawer')?.click();
        };

        nuxt.$router.beforeEach(() => {
            appStore.menuOpen = false;
        });

        // todo create a global search functionality
        watch(
            () => authStore.isAuthenticated,
            async authenticated => {
                if (authenticated) {
                    await Promise.all([
                        apiFetch<{ productTypes: ProductType[] }>('/constants').then(data => {
                            productTypes.value = data.productTypes;
                            return;
                        }),
                        apiFetch<UnitAttributes[]>('/units').then(data => {
                            unitStore.units = data;
                            return;
                        }),
                        apiFetch<PackagingAttributes[]>('/packagings').then(data => {
                            packagingStore.packagings = data;
                            return;
                        })
                    ]);
                } else {
                    productTypes.value = [];
                }
            },
            { immediate: true }
        );

        await Promise.all([
            productStore.loadProducts(),
            apiFetch<IngredientAttributes[]>('/ingredients?with[0]=products_count')
                .then(ingredients => ingredientStore.ingredients = ingredients)
        ]);

        return {
            ingredientEditor,
            logout,
            addIngredient,
            userName,
            closeMenu
        };
    }
});
</script>
